import React from 'react';
import styled from 'styled-components';


const BackgroundDiv = styled.div`
    background-color: #141423;
    background-image: url('images/texture.png');
    width: 100vw;
    height: 100vh;
`;

const Text = styled.img`
    position: absolute; 
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    padding: 30px;
    width: 200px;
`;

const LogoImage = styled.img`
    position: absolute; 
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto; 
    width: 700px;
    max-width: 90vw;
`;

const JellyLabsSticker = styled.img`
    position: absolute; 
    left: 0;
    top: 0;
    padding: 30px;

    @media screen and (max-width: 750px){
        display: none;
    }
`;

const Banner = styled.img`
    position: absolute; 
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding: 30px;
    width: 200px;
`;

const Sticker = styled.img`
    position: absolute; 
    right: 0;
    bottom: 0;
    padding: 30px;

    @media screen and (max-width: 750px){
        display: none;
    }
`;

export default function Background() {
    return <BackgroundDiv>
        <JellyLabsSticker src='images/jelly_labs_sticker.svg' />
        <Text src='images/top_text.svg' />
        <LogoImage src='images/logo.svg' />
        <Banner src='images/text_line.svg' />
        <Sticker src='images/sticker.svg' />
    </BackgroundDiv>
}
